import { Component, h } from "preact";
import { Route, Router, RouterOnChangeArgs } from "preact-router";

import Home from "../routes/home";
import Profile from "../routes/profile";
import Invite from "../routes/invite";
import Bounty from "../routes/bounty";
import SharedArticle from "../routes/share";
import NotFoundPage from "../routes/notfound";

import analytics from './analytics'

const inBrowser = typeof window !== "undefined"

export default class App extends Component {
	componentDidMount() {
		analytics.on('pageEnd', ({ payload }) => {
			console.log('pageView fired from analytics', payload.properties)
		})
	}
	/** Gets fired when the route changes.
	 *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
	 *	@param {string} event.url	The newly routed URL
	 */
	handleRoute = e => {
		this.currentUrl = e.url
		this.previousUrl = e.previous
		if (inBrowser) {
			analytics.page()
		}
	};

	render() {
        return (
            <div id="app" class="container">
                <Router onChange={this.handleRoute}>
                    <Route path="/" component={Home} />
                    <Route path="/users/:user" component={Profile} />
                    <Route path="/invitations/:code" component={Invite} />
                    <Route path="/articles/:guid" component={SharedArticle} />
                    <Route path="/bounties/:code" component={Bounty} />
                    <NotFoundPage default />
                </Router>
            </div>
        );
	}
}