import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'

import { GA_TRACKING_ID } from "../models/const";

const analytics = Analytics({
  app: 'newspie',
  debug: true,
  plugins: [
    googleAnalytics({
      trackingId: GA_TRACKING_ID,
    }),
  ]
})

export default analytics